<header class="position-fixed w-100">
  <div class="">
    <nav class="container-fluid">
      <div class="menu d-flex align-items-center justify-content-between">
        <div class="d-flex align-items-center">
          <div class="icon-menu d-lg-none" (click)="open = !open">
            <img
              *ngIf="!open"
              class="open"
              src="./assets/images/svg/open-menu.svg"
              alt=""
            />
            <img
              *ngIf="open"
              class="open"
              src="./assets/images/svg/close-gray.svg"
              alt=""
            />
          </div>
          <a routerLink="/">
            <img
              class="img-fluid logo"
              src="./assets/images/svg/logo.svg"
              alt="logo"
            />
          </a>
        </div>

        <div class="links d-none d-lg-block">
          <a
            class="cursor-pointer"
            routerLink="/lottery"
            routerLinkActive="active"
            [routerLinkActiveOptions]="{ exact: true }"
            >INICIO</a
          >
          <a
            class="cursor-pointer"
            routerLink="/lottery/premios"
            routerLinkActive="active"
            [routerLinkActiveOptions]="{ exact: true }"
            >PREMIOS</a
          >
          <a
            class="cursor-pointer"
            routerLink="/lottery/ganadores"
            routerLinkActive="active"
            [routerLinkActiveOptions]="{ exact: true }"
            >GANADORES</a
          >
          <a
            class="cursor-pointer"
            routerLink="/lottery/beneficios"
            routerLinkActive="active"
            [routerLinkActiveOptions]="{ exact: true }"
            >BENEFICIOS</a
          >
          <a
            class="cursor-pointer"
            routerLink="/planes"
            routerLinkActive="active"
            [routerLinkActiveOptions]="{ exact: true }"
            >PLANES</a
          >
        </div>

        <div *ngIf="isLoading" class="loader">
          <div class="spinner-border text-primary" role="status">
            <span class="visually-hidden">Loading</span>
          </div>
        </div>
        <div class="login" *ngIf="!isLoading">
          <a
            class="btn border-0 d-flex align-items-center justify-content-center"
            routerLink="/login"
            *ngIf="!userBasicInfo"
            >Iniciar Sesión
            <img
              src="assets/images/svg/login.svg"
              alt="login"
              class="img-fluid ms-2"
            />
          </a>

          <div class="btn-group" dropdown *ngIf="userBasicInfo">
            <button
              id="button-basic"
              dropdownToggle
              type="button"
              class="btn-profile dropdown-toggle"
              aria-controls="dropdown-basic"
            >
              <div class="user-info">
                <span class="user-name">{{
                  userBasicInfo?.name || userBasicInfo?.email
                }}</span>
                <span
                  class="text-plan"
                  [ngStyle]="{
                    color: userBasicInfo?.latestPlanName ? '#D200EA' : 'inherit'
                  }"
                >
                  {{ userBasicInfo?.latestPlanName || "Sin Plan" }}
                </span>
              </div>
              <img
                [src]="userBasicInfo?.photoUrl"
                class="picture ms-2"
                *ngIf="userBasicInfo?.photoUrl"
              />
              <img
                src="assets/images/svg/login.svg"
                alt="login"
                class="img-fluid ms-2"
                *ngIf="!userBasicInfo?.photoUrl"
              />
            </button>
            <ul
              id="dropdown-basic"
              *dropdownMenu
              class="dropdown-menu dropdown-menu-right"
              role="menu"
              aria-labelledby="button-basic"
            >
              <li role="menuitem">
                <button class="dropdown-item" (click)="logoutGoogle()">
                  Cerrar Sesión
                </button>
              </li>
              <li role="menuitem">
                <button class="dropdown-item" (click)="profileSoon()">
                  Perfil
                </button>
              </li>
            </ul>
          </div>
        </div>
      </div>
    </nav>
  </div>

  <div class="menu-responsive d-lg-none" [class.open]="open">
    <div class="links">
      <div class="link" (click)="open = !open">
        <a
          class="cursor-pointer color-secondary"
          routerLink="/lottery"
          routerLinkActive="active"
          [routerLinkActiveOptions]="{ exact: true }"
          >INICIO</a
        >
      </div>
      <div class="link" (click)="open = !open">
        <a
          class="cursor-pointer color-secondary"
          routerLink="/lottery/premios"
          routerLinkActive="active"
          [routerLinkActiveOptions]="{ exact: true }"
          >PREMIOS</a
        >
      </div>
      <div class="link" (click)="open = !open">
        <a
          class="cursor-pointer color-secondary"
          routerLink="/lottery/ganadores"
          routerLinkActive="active"
          [routerLinkActiveOptions]="{ exact: true }"
          >GANADORES</a
        >
      </div>
      <div class="link" (click)="open = !open">
        <a
          class="cursor-pointer color-secondary"
          routerLink="/lottery/beneficios"
          routerLinkActive="active"
          [routerLinkActiveOptions]="{ exact: true }"
          >BENEFICIOS</a
        >
      </div>
      <div class="link" (click)="open = !open">
        <a
          class="cursor-pointer color-secondary"
          routerLink="/planes"
          routerLinkActive="active"
          [routerLinkActiveOptions]="{ exact: false }"
          >PLANES</a
        >
      </div>
    </div>
  </div>
</header>
