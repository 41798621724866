import { Component, OnInit } from '@angular/core';
import { BsModalRef, BsModalService } from 'ngx-bootstrap/modal';
import { Router } from '@angular/router';
import { StorageService } from '../../../services/storage.service';

@Component({
  selector: 'app-payment-odds-modal',
  templateUrl: './payment-odds-modal.component.html',
  styleUrls: ['./payment-odds-modal.component.scss'],
})
export class PaymentOddsModalComponent implements OnInit {
  modalRef?: BsModalRef;
  planBuy: any;
  itemsData: any;
  operationNumber: string;
  transactionDate: any;
  paymentMethod: any;
  amount: any;
  currency: any;
  purchaseOdds: any;
  user: any;

  constructor(
    public modalService: BsModalService,
    private storage: StorageService,
    private router: Router
  ) {}

  ngOnInit(): void {
    this.planBuy = this.storage.get('plan');
    this.modalService.onHidden.subscribe(() => {
      this.redirectToLotery();
    });
  }

  closeModal(): void {
    this.modalService.hide();
    this.redirectToLotery();
  }

  private redirectToLotery(): void {
    this.router.navigate(['/lottery/premios']);
  }
}
