import { AfterViewInit, Component, OnInit, OnDestroy } from '@angular/core';
import { ActivatedRoute, NavigationEnd, Router } from '@angular/router';
import { take } from 'rxjs/operators';
import { GoogleService } from '../../../services/google.service';
import { StorageService } from '../../../services/storage.service';
import { AuthService } from '../../../services/auth.service';
import { GeneralService } from '../../../services/general.service';
import { Subscription } from 'rxjs';
import { SweetAlertService } from 'app/services/sweet-alert.service';

@Component({
  selector: 'app-header',
  templateUrl: './header.component.html',
  styleUrls: ['./header.component.scss'],
})
export class HeaderComponent implements OnInit, AfterViewInit, OnDestroy {
  open: boolean;
  currentRoute: string;
  userData: any;
  userId;
  previousUserData: any;
  userBasicInfo: any;
  userPlans: any[];
  latestPlanId: number;
  latestPlanName: string;
  auxToken: any = 'auxToken';
  isLoading: boolean = false;
  private userSubscription: Subscription;

  constructor(
    private activatedRoute: ActivatedRoute,
    private generalService: GeneralService,
    private router: Router,
    private storageService: StorageService,
    private authService: AuthService,
    private googleService: GoogleService,
    private sweetAlert: SweetAlertService
  ) {}

  ngOnInit(): void {
    this.subscribeToRouterEvents();

    this.storageService.getTokenObservable().subscribe((token) => {
      const firstLogin = this.storageService.get('firstLogin');

      if (this.isUserLoggedIn()) {
        this.isLoading = true;
        this.initializeUserData();

        if (!firstLogin) {
          this.storageService.set('firstLogin', 'true');
          location.reload();
        } else {
          this.loadUserData();
        }
      } else {
        this.initializeUserData();
      }
    });

    // Se vuelven a cargar los datos si se detecta un cambio en el objeto user
    this.userSubscription = this.generalService.user$.subscribe((user) => {
      if (user && !this.isEqual(user, this.previousUserData)) {
        this.previousUserData = user;
        this.loadUserData();
      }
    });
  }

  ngOnDestroy(): void {
    if (this.userSubscription) {
      this.userSubscription.unsubscribe();
    }
  }

  subscribeToRouterEvents(): void {
    this.router.events.subscribe((event) => {
      if (event instanceof NavigationEnd) {
        this.currentRoute = this.extractRouteName(event.url);
      }
    });
  }

  extractRouteName(url: string): string {
    const routeParts = url.split('/');
    return routeParts[routeParts.length - 1];
  }

  initializeUserData(): void {
    const token = this.storageService.get('token');
    if (token) {
      const storedUser = this.storageService.get('user');
      const action = this.storageService.get('action');
      if (storedUser) {
        this.userData = storedUser;

        // se valida si existe la info del usuario:
        if (this.storageService.get('userBasicInfo')) {
          this.userBasicInfo = this.storageService.get('userBasicInfo');
          // si no hay photoUrl, se fuerza una recarga
          if (!this.userBasicInfo.photoUrl) {
            this.updateUserFromBackend();
          } else {
            this.isLoading = false;
          }
        } else {
          // this.userBasicInfo = this.storageService.get('userBasicInfo');
          if (action === 'login') {
            this.updateUserFromBackend();
          } else {
            this.loadUserData();
          }
        }
      } else if (
        action === 'loginGoogle' ||
        action === 'userValidatedSuccesfully'
      ) {
        this.initializeGoogleUser();
      } else if (action === 'pendingValidation') {
        this.router.navigateByUrl('/validation-code');
        return;
      } else {
        this.loadUserData();
      }
      this.auxToken = token;
    }
  }

  initializeGoogleUser(): void {
    this.googleService.eventUsertype.subscribe((res) => {
      this.userData = {
        name: res.name,
        email: res.email,
        photoUrl: res.picture,
      };
      this.storageService.set('user', this.userData);
      this.loadUserData();
    });
  }

  updateUserFromBackend(): void {
    this.generalService.getUser().subscribe((res) => {
      console.log('UPDATE DE USER DESDE EL BACK', res.data);
      if (res.data) {
        this.storageService.set('country', res.data.countryDTO);
      }

      // Limpia la URL de la foto si contiene un segundo "https://"
      let photoUrl = res.data.photoUrl || null;
      if (photoUrl && photoUrl.indexOf('https://', 8) !== -1) {
        const index = photoUrl.indexOf('https://', 8);
        photoUrl = photoUrl.substring(index);
      }

      this.userData = {
        name: `${res.data.firstname}${
          res.data.lastname ? ` ${res.data.lastname}` : ''
        }`,
        email: res.data.email,
        photoUrl: photoUrl, // Asigna la URL limpia
      };

      this.storageService.set('user', this.userData);
      this.loadUserData();
    });
  }

  loadUserData(): void {
    if (this.userData) {
      console.log('User data:', this.userData);
      this.generalService.getUser().subscribe((res) => {
        this.userPlans = res.data.plansUser;
        this.userId = res.data.id;
        this.updateLatestPlan();
        this.storeUserBasicInfo();
        this.userBasicInfo = this.storageService.get('userBasicInfo');
        console.log('this.userBasicInfo', this.userBasicInfo);
        if (this.userBasicInfo.photoUrl) {
          this.isLoading = false;
        }
      });
    }
  }

  updateLatestPlan(): void {
    let latestDate = new Date(0);
    if (this.userPlans) {
      this.userPlans.forEach((plan) => {
        const planEndDate = new Date(plan.subscriptionEnds);
        if (planEndDate > latestDate) {
          latestDate = planEndDate;
          this.latestPlanId = plan.id;
          this.latestPlanName = plan.name;
        }
      });
    }
  }

  storeUserBasicInfo(): void {
    let photoUrl = this.userData.photoUrl;

    // Limpia la URL de la foto si contiene un segundo "https://"
    if (photoUrl && photoUrl.indexOf('https://', 8) !== -1) {
      const index = photoUrl.indexOf('https://', 8);
      photoUrl = photoUrl.substring(index);
    }
    const userData = {
      name: this.userData.name,
      userId: this.userId,
      photoUrl: this.userData.photoUrl,
      latestPlanName: this.latestPlanName,
      userPlans: this.userPlans,
    };
    this.storageService.set('userBasicInfo', userData);
    this.userData = { ...this.userData, ...userData };
  }

  ngAfterViewInit(): void {
    this.activatedRoute.fragment.pipe(take(1)).subscribe((fragment) => {
      if (fragment) {
        this.scrollToFragment(fragment);
      }
    });
  }

  scrollToFragment(fragment: string): void {
    const element = document.getElementById(fragment);
    if (element) {
      element.scrollIntoView({
        behavior: 'smooth',
        block: 'start',
        inline: 'nearest',
      });
    }
  }

  scrollTo(anchor: string): void {
    this.open = false;
    const element = document.getElementById(anchor);
    if (element) {
      element.scrollIntoView();
    }
  }

  logoutGoogle(): void {
    this.googleService.logout();
    this.router.navigateByUrl('/login');
  }

  private isUserLoggedIn(): boolean {
    const token = this.storageService.get('token');
    return token !== undefined && token !== null;
  }

  private isEqual(obj1: any, obj2: any): boolean {
    return JSON.stringify(obj1) === JSON.stringify(obj2);
  }

  profileSoon(): void {
    this.sweetAlert.toastWarning('Pronto podrás administrar tu perfil!');
  }
}
