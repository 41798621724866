<div class="row">
  <div class="col-3 form-group">
    <div class="input-group">
      <input #input1 (keyup)="input1Change()" inputmode="numeric" type="text"
             class="form-control text-center readonly-white px-0" maxlength="1" onlyNumbers [readonly]="readonly">
    </div>
  </div>
  <div class="col-3 form-group">
    <div class="input-group">
      <input #input2 (keyup)="input2Change()" inputmode="numeric"
             [attr.type]="(inputCard && secretCard) ? 'password' : 'text'"
             type="text" class="form-control text-center readonly-white px-0" maxlength="1" onlyNumbers
             [readonly]="readonly">
    </div>
  </div>
  <div class="col-3 form-group">
    <div class="input-group">
      <input #input3 (keyup)="input3Change()" inputmode="numeric"
             [attr.type]="(inputCard && secretCard) ? 'password' : 'text'" type="text"
             class="form-control readonly-white text-center px-0" maxlength="1" onlyNumbers [readonly]="readonly">
    </div>
  </div>
  <div class="col-3 form-group">
    <div class="input-group">
      <input #input4 (keyup)="input4Change()" inputmode="numeric" type="text"
             class="form-control readonly-white text-center px-0" maxlength="1" onlyNumbers [readonly]="readonly">
    </div>
  </div>
</div>
