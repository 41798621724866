import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { AwardsService } from './awards.service';
import { HTTP_INTERCEPTORS, HttpClientModule } from '@angular/common/http';
import { AuthInterceptorService } from '../interceptors/auth-interceptor.service';
import { GeneralService } from './general.service';
import { PlainsService } from './plains.service';
import { AuthService } from './auth.service';
import { OAuthModule } from 'angular-oauth2-oidc';


@NgModule( {
  declarations: [],
  imports: [
    CommonModule,
    HttpClientModule,
    OAuthModule.forRoot()
  ],
  providers: [
    AwardsService,
    GeneralService,
    PlainsService,
    AuthService,
    {
      provide: HTTP_INTERCEPTORS, useClass: AuthInterceptorService, multi: true
    }
  ]
} )
export class ServicesModule {}
