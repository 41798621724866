import { Injectable } from '@angular/core';
import Swal, { SweetAlertOptions } from 'sweetalert2';

@Injectable({
  providedIn: 'root',
})
export class SweetAlertService {
  constructor() {}

  alert(options: SweetAlertOptions) {
    const alert = Swal.mixin({
      customClass: {
        popup: 'br-0 py-5',
        confirmButton: 'btn btn-primary shadow-none px-5 mx-1 mb-1',
        cancelButton: 'btn btn-danger shadow-none px-5 mx-1 mb-1',
      },
      title: '',
      icon: 'success',
      confirmButtonText: 'Aceptar',
      cancelButtonText: 'Cancelar',
      allowEscapeKey: false,
      allowOutsideClick: false,
      buttonsStyling: false,
      ...options,
    });

    return alert.fire();
  }

  toast(options: SweetAlertOptions) {
    const Toast = Swal.mixin({
      toast: true,
      position: 'bottom-left',
      showConfirmButton: false,
      timer: 7000,
      timerProgressBar: true,
      ...options,
      didOpen: (toast) => {
        toast.addEventListener('mouseenter', Swal.stopTimer);
        toast.addEventListener('mouseleave', Swal.resumeTimer);
      },
    });

    Toast.fire();
  }

  toastError(text: string) {
    return this.toast({ icon: 'error', title: 'Error', text });
  }

  toastWarning(text: string) {
    return this.toast({ icon: 'warning', title: 'Alerta!', text });
  }

  toastSuccess(text: string, title = '') {
    return this.toast({ icon: 'success', title, text });
  }
}
