<div class="bg-modal p-4">
  <div>
    <div class="d-flex justify-content-center align-items-center flex-column mt-4">
      <img src="assets/images/home/keola-lotery.png" class="img-pay">
      <h2 class="color-secondary font-black ft-20 mt-4">¡Error!</h2>
      <p class="color-4 ft-12">Lo sentimos , algo fallo al intentar realizar el pago</p>
      <div class="d-flex">
        <img src="assets/images/svg/icon-money.svg" alt="" class="me-4">
        <div>
          <p class="ft-18 color-4 mb-0">{{ error }}</p>
        </div>
      </div>
      <button (click)="modalService.hide()" class="btn btn-login mt-4">Aceptar</button>
    </div>
  </div>
</div>
