import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable } from 'rxjs';

@Injectable({
  providedIn: 'root',
})
export class StorageService {
  protected storage = sessionStorage;
  private tokenSubject: BehaviorSubject<string | null>;

  constructor() {
    const token = this.storage.getItem('token');
    this.tokenSubject = new BehaviorSubject<string | null>(token);
  }

  get<T = any>(key: string): string | null | T {
    if (this.storage.getItem(key)) {
      try {
        return JSON.parse(this.storage.getItem(key) as string) as T;
      } catch (e) {
        return this.storage.getItem(key);
      }
    } else {
      return null;
    }
  }

  set(key: string, data: any, parse = true): void {
    this.storage.setItem(key, parse ? JSON.stringify(data) : data);
    if (key === 'token') {
      this.tokenSubject.next(data);
    }
  }

  remove(key: string): void {
    this.storage.removeItem(key);
    if (key === 'token') {
      this.tokenSubject.next(null);
    }
  }

  removeListKeys(listKeys: string[]): void {
    listKeys.forEach((key: string) => {
      this.remove(key);
    });
  }

  deleteAllLocalStorage(): void {
    const items = Object.keys(localStorage);
    items.forEach((key) => {
      this.remove(key);
    });
  }

  getTokenObservable(): Observable<string | null> {
    return this.tokenSubject.asObservable();
  }
}
