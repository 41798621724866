import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FooterComponent } from './footer/footer.component';
import { HeaderComponent } from './header/header.component';
import { CarouselModule } from 'ngx-bootstrap/carousel';
import { RouterLink, RouterLinkActive } from '@angular/router';
import { TabsModule } from 'ngx-bootstrap/tabs';
import { NgxLoaderIndicatorModule } from 'ngx-loader-indicator';
import { NgxPayPalModule } from 'ngx-paypal';
import { ModalModule } from 'ngx-bootstrap/modal';
import { ControlMessagesComponent } from './control-messages/control-messages.component';
import { BsDropdownModule } from 'ngx-bootstrap/dropdown';
import { InputCodeComponent } from './input-code/input-code.component';
import { DirectivesModule } from '../../directives/directives.module';
import { PaymentMethodComponent } from './payment-method/payment-method.component';
import { FormsModule } from '@angular/forms';


@NgModule( {
  declarations: [
    FooterComponent,
    HeaderComponent,
    ControlMessagesComponent,
    InputCodeComponent,
    PaymentMethodComponent,

  ],
  imports: [
    CommonModule,
    CarouselModule.forRoot(),
    TabsModule.forRoot(),
    RouterLink,
    RouterLinkActive,
    NgxLoaderIndicatorModule.forRoot(),
    NgxPayPalModule,
    ModalModule.forRoot(),
    BsDropdownModule.forRoot(),
    DirectivesModule,
    FormsModule
  ],
  exports: [
    HeaderComponent,
    FooterComponent,
    CarouselModule,
    NgxPayPalModule,
    ModalModule,
    ControlMessagesComponent,
    InputCodeComponent,

  ]
} )
export class SharedModule {}
