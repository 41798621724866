import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { environment } from 'environments/environment';
import { Observable, throwError } from 'rxjs';
import { catchError, map } from 'rxjs/operators';

export interface IWithdrawBody {
  accountId: number;
  withdrawalAmount: number;
  typeMovement: number;
  currency: string;
  userId: number;
  planId: number;
}

export type PaymentSource = 'PAYPAL' | 'CULQI' | 'KEOLA_PAY';

export interface ICompleteBuyPay {
  planId: number;
  paymentID: string;
  referenceCode?: string | null;
  paymentSource: PaymentSource;
  currency: string;
  amount: number;
}

export interface Option {
  id: number;
  options: number;
}

export interface ICompleteBuyOdds extends ICompleteBuyPay {
  probabilitys: Option[];
}

export interface ICompleteBuyPlan extends ICompleteBuyPay {
  months: string;
}

export interface IDoubleChance {
  userId: number;
  planId: number;
  months: string;
}

@Injectable({
  providedIn: 'root',
})
export class KeolaPayService {
  private apiUrl = environment.apiUrlKeola;

  constructor(private http: HttpClient) {}

  private request(method: string, url: string, body?: any): Observable<any> {
    const headers = new HttpHeaders({
      'Content-Type': 'application/json',
    });

    return this.http
      .request(method, this.apiUrl + url, {
        body,
        headers,
      })
      .pipe(
        catchError((error) => {
          console.error('API request error', error);
          return throwError(error);
        })
      );
  }

  getWallets() {
    return this.request('GET', '/wallet-virtual/walletsbyiduser');
  }

  getUserProbabilities(): Observable<any> {
    return this.request('GET', '/probability-increase/for-user');
  }

  withdrawFunds(body: IWithdrawBody): Observable<any> {
    return this.request('POST', '/wallet-virtual/withdraw', body);
  }

  completeBuyPlan(body: ICompleteBuyPay): Observable<any> {
    return this.request('POST', '/payment/complete-buy-plan', body);
  }

  completeBuyProbability(body: ICompleteBuyOdds): Observable<any> {
    return this.request('POST', '/payment/complete-buy-probability', body);
  }

  assignPointsLottery(userId: number, subcriptionId: number): Observable<any> {
    const body = { userId, subcriptionId };
    return this.request('POST', '/cash-bank/assign-points-lotery', body);
  }

  assignDoubleChancePoints(body: IDoubleChance): Observable<any> {
    return this.request('POST', '/double-chance/create', body);
  }
}
