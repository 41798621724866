import { Directive, ElementRef, HostListener, Input } from '@angular/core';
import { Utils } from '@components/utils/utils';


@Directive( {
  selector: '[onlyNumbers]'
} )
export class OnlyNumbersDirective {

  @Input() maxlength: number;
  private pattern = /^[0-9]*$/;

  constructor( private el: ElementRef ) {

    
  }

  @HostListener( 'keypress', ['$event'] ) onKeyPress( event: { key: string; } ): boolean {
    return new RegExp( this.pattern ).test( event.key );
  }

  @HostListener( 'paste', ['$event'] ) blockPaste( event: KeyboardEvent ): void {
    setTimeout( () => {
      this.el.nativeElement.value = Utils.filterNumbers( this.el.nativeElement.value );
    }, 1 );
  }

  filterNumbers( value: string ) {
    if ( value && typeof value === 'string' ) {
      const arr = [];
      value.split( '' ).forEach( n => {
        if ( this.pattern.test( n ) ) {
          arr.push( n );
        }
      } );
      return arr.join( '' );
    } else {
      return value;
    }
  }


}
