import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Observable, of } from 'rxjs';
import { map, catchError } from 'rxjs/operators';
import { environment } from '../../environments/environment';

interface ScriptElement {
  id: string;
  url: string;
}

@Injectable({
  providedIn: 'root'
})
export class CulqiService {
  private culqiBaseUrl = 'https://api.culqi.com/v2/customers';
  private headers = new HttpHeaders({
    'Authorization': `Bearer ${environment.culqiSecretKey}`,
    'skipInterceptor': 'true'
  });

  constructor(private http: HttpClient) {}

  public loadScript({ id, url }: ScriptElement): Promise<{ id: string; loaded: boolean; status: string }> {
    return new Promise((resolve, reject) => {
      if (document.getElementById(id)) {
        resolve({ id, loaded: true, status: 'Already Loaded' });
        return;
      }
      const script = document.createElement('script');
      script.type = 'text/javascript';
      script.src = url;
      script.id = id;
      script.async = true;
      script.defer = true;
      script.onload = () => resolve({ id, loaded: true, status: 'Loaded' });
      script.onerror = (error: any) => reject({ id, loaded: false, status: 'Error Loading Script', error });
      document.body.appendChild(script);
    });
  }

  public removeScript(scriptIds: string[]): void {
    scriptIds.forEach(id => {
      const scriptElement = document.getElementById(id);
      if (scriptElement) {
        scriptElement.remove();
      }
    });
  }

  public getClients(email: string): Observable<any> {
    if (!email) {
      console.error('Email is required to fetch clients.');
      return of(null);
    }

    const url = `${this.culqiBaseUrl}?email=${encodeURIComponent(email)}`;
    return this.http.get<any>(url, { headers: this.headers }).pipe(
      map(response => response),
      catchError(error => {
        console.error('Error fetching clients:', error);
        return of(null);
      })
    );
  }

  public createClient(params: any): Observable<any> {
    if (!params || typeof params !== 'object') {
      console.error('Valid params are required to create a client.');
      return of(null);
    }

    return this.http.post<any>(this.culqiBaseUrl, params, { headers: this.headers }).pipe(
      map(response => response),
      catchError(error => {
        console.error('Error creating client:', error);
        return of(null);
      })
    );
  }
}
