import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { environment } from '../../environments/environment';
import { map, BehaviorSubject, Observable } from 'rxjs';

@Injectable({
  providedIn: 'root',
})
export class GeneralService {
  url = environment.apiUrlKeola;
  private userSubject: BehaviorSubject<any>;
  public user$: Observable<any>;

  constructor(private http: HttpClient) {
    this.userSubject = new BehaviorSubject<any>(null);
    this.user$ = this.userSubject.asObservable();
  }

  get(module: string, type: string) {
    let url = `${this.url}/${module}/${type}`;
    return this.http.get(url).pipe(map((resp: any) => resp));
  }

  getCountries() {
    return this.http.get(`${this.url}/common/countries`).pipe();
  }

  getUser() {
    let url = `${this.url}/user/find`;
    return this.http.get(url).pipe(
      map((resp: any) => {
        this.userSubject.next(resp);
        return resp;
      })
    );
  }

  updateUser(user: any): void {
    this.userSubject.next(user);
  }
}
