import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { environment } from '../../environments/environment';
import { BehaviorSubject, map } from 'rxjs';

@Injectable({
  providedIn: 'root',
})
export class PlainsService {
  url = environment.apiUrlKeola;
  plain = new BehaviorSubject<any>('');
  dataPayment = new BehaviorSubject<any>(null);

  constructor(private http: HttpClient) {}

  getByID(module, type, id) {
    let url = this.url + module + '/' + type + '/' + id;

    return this.http.get(url).pipe(
      map((resp: any) => {
        return resp;
      })
    );
  }

  paymentPaypal(params) {
    let url = this.url + '/payment/complete-buy-plan';

    return this.http.post(url, params).pipe(
      map((resp: any) => {
        return resp;
      })
    );
  }

  // Comprar Probs con Paypal:
  paymentPaypalOdds(params) {
    let url = this.url + '/payment/complete-buy-probability';

    return this.http.post(url, params).pipe(
      map((resp: any) => {
        return resp;
      })
    );
  }

  getChances(id) {
    let url = this.url + '/double-chance/list-rules/' + id;

    return this.http.get(url).pipe(
      map((resp: any) => {
        return resp.data;
      })
    );
  }

  registerChances(params) {
    let url = this.url + '/double-chance/create';

    return this.http.post(url, params).pipe(
      map((resp: any) => {
        return resp;
      })
    );
  }
}
