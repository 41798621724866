import * as moment from 'moment';

export class Utils {
  static formatDate( date: any ) {
    moment.locale( 'es' );
    if ( date ) {
      //const fechaActual = moment();
      // Formatear la fecha en el formato deseado
      //date = fechaActual.format( 'DD [de] MMMM' );

      const fechaFormateada = moment(date).format('DD [de] MMMM');
      return fechaFormateada;
    }
    return date;
  }

  static genders() {
    return [
      {
        id: 'M',
        text: 'MASCULINO'
      },
      {
        id: 'F',
        text: 'FEMENINO'
      }
    ];
  }

  static filterNumbers( value: string ) {
    const pattern = /^[0-9]*$/;
    if ( value && typeof value === 'string' ) {
      const arr = [];
      value.split( '' ).forEach( n => {
        if ( pattern.test( n ) ) {
          arr.push( n );
        }
      } );
      return arr.join( '' );
    } else {
      return value;
    }
  }
}
