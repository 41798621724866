import { Injectable } from '@angular/core';
import { HttpErrorResponse, HttpEvent, HttpHandler, HttpInterceptor, HttpRequest } from '@angular/common/http';
import { Router } from '@angular/router';
import { catchError, Observable, throwError } from 'rxjs';
import { StorageService } from '../services/storage.service';
import { environment } from '../../environments/environment';

@Injectable( {
  providedIn: 'root'
} )
export class AuthInterceptorService implements HttpInterceptor {

  constructor( private router: Router,
               private storage: StorageService ) {
  }

  intercept( req: HttpRequest<any>, next: HttpHandler ): Observable<HttpEvent<any>> {
    const isGoogleService = req.url.toLowerCase().includes( 'google' );
    const token = this.storage.get<string>( 'token' );
    if ( req.headers.has( 'skipInterceptor' ) ) {
      const headers = req.headers.delete( 'skipInterceptor' );
      const request = req.clone( { headers } );
      return next.handle( request );
    }

    const headers = token
      ? {
        ...( isGoogleService ? {} : { 'authorizationtoken': `Bearer ${token}`, 'x-api-key': environment.x_api_key } ),
      }
      : {
        ...( isGoogleService ? {} : { 'x-api-key': environment.x_api_key } )
      };

    const request = req.clone( { setHeaders: headers } );

    return next.handle( request ).pipe(
      catchError( ( err: HttpErrorResponse ) => {
        console.log( 'InterceptorService:', err );
        const error = err?.error?.message || err?.message || err;

        if ( err.status === 401 && !this.router.url.includes( '/' ) ) {
          this.router.navigateByUrl( '/login' );
          setTimeout( () => {
            window.location.reload();
          }, 300 );
        }

        return throwError( () => error );
      } )
    );
  }
}
