import { EventEmitter, Injectable } from '@angular/core';
import { AuthConfig, OAuthService } from 'angular-oauth2-oidc';
import { AuthService } from './auth.service';
import { StorageService } from './storage.service';
import { Router } from '@angular/router';
import { BehaviorSubject, Subject } from 'rxjs';
import { HttpClient } from '@angular/common/http';

@Injectable({
  providedIn: 'root',
})
export class GoogleService {
  eventUsertype = new Subject<any>();
  userAction = new Subject<any>();
  onCreated = new EventEmitter<any>();
  userData: any;
  idCountry: number;
  isLoading$ = new BehaviorSubject<boolean>(false);

  constructor(
    private oauthService: OAuthService,
    private storageService: StorageService,
    private router: Router,
    private authService: AuthService,
    private http: HttpClient
  ) {
    this.initLoginGoogle();
    this.idCountry = this.storageService.get('country')?.id;
  }

  initLoginGoogle() {
    const config: AuthConfig = {
      issuer: 'https://accounts.google.com',
      strictDiscoveryDocumentValidation: false,
      clientId:
        '628295289316-tg42ivna06uivsiflpd7eudi5smp9p83.apps.googleusercontent.com',
      redirectUri: window.location.origin + '/lottery',
      scope:
        'openid profile email https://www.googleapis.com/auth/user.addresses.read',
    };

    this.oauthService.configure(config);
    this.oauthService.setupAutomaticSilentRefresh();
    this.oauthService
      .loadDiscoveryDocumentAndTryLogin()
      .then(() => {
        if (
          !this.oauthService.hasValidIdToken() ||
          !this.oauthService.hasValidAccessToken()
        ) {
          // Maneja el caso en que el usuario no está autenticado
          console.log(
            'El usuario canceló la autenticación o no completó el inicio de sesión.'
          );
          this.storageService.remove('action');
          this.isLoading$.next(false); // Finaliza la carga
          return;
        }
        const identityClaims = this.oauthService.getIdentityClaims();
        console.log('Identity Claims:', identityClaims);
        if (identityClaims) {
          this.eventUsertype.next(identityClaims);
          this.userData = identityClaims;
          this.storageService.set('sub', identityClaims['sub']); // Guardar el sub en el storage
          this.userAction.next(this.storageService.get('action'));
        }

        const accessToken = this.oauthService.getAccessToken();
        if (accessToken) {
          // Verificar si ya existe un valor de 'country' en el storage
          const storedCountry = this.storageService.get('country');

          if (storedCountry) {
            // Si existe un valor de 'country' en el storage, usar ese valor
            this.idCountry = storedCountry.id;
            console.log('Using stored country:', storedCountry);
          } else {
            // NUEVO PAÍS POR DEFECTO
            const defaultCountry = {
              id: 20,
              abbreviation: 'US',
              name: 'Estados Unidos',
              prefix: '+1',
              currency: 'USD',
              flag: 'https://s3.amazonaws.com/keola.dev.imagen/flag/US.png',
            };

            this.getUserCountry(accessToken).subscribe(
              (profile) => {
                console.log('COUNTRY PROFILE:', profile);
                if (
                  profile &&
                  profile.addresses &&
                  profile.addresses.length > 0
                ) {
                  this.idCountry = profile.addresses[0].country;
                } else {
                  this.idCountry = defaultCountry.id; // Valor por defecto si no se encuentra el país
                }
                console.log('this.idCountry:', this.idCountry);
                this.storageService.set('country', defaultCountry);
              },
              (error) => {
                console.error('Error fetching user profile:', error);
                this.idCountry = defaultCountry.id; // Valor por defecto en caso de error
                this.storageService.set('country', defaultCountry);
              }
            );
          }
        }
      })
      .catch((error) => {
        console.log('Error durante autenticación:', error);
        this.storageService.remove('action');
        this.isLoading$.next(false); // Finaliza la carga en caso de error
      });
  }

  login() {
    this.oauthService.initLoginFlow();
  }

  logout() {
    this.oauthService.logOut();
    this.eventUsertype.next(false);
    this.storageService.remove('user');
    this.storageService.remove('token');
    this.storageService.remove('sub');
    this.storageService.remove('userBasicInfo');
    this.storageService.remove('action');
    this.storageService.remove('firstLogin');
    setTimeout(() => window.location.reload(), 300);
  }

  removeCredentials() {
    this.oauthService.logOut();
    this.eventUsertype.next(false);
    this.storageService.remove('user');
    this.storageService.remove('token');
    this.storageService.remove('sub');
    this.storageService.remove('userBasicInfo');
    this.storageService.remove('action');
    this.storageService.remove('firstLogin');
  }
  getProfileGoogle() {
    const claims: any = this.oauthService.getIdentityClaims();
    // Guardar el sub en el storage
    if (claims) {
      this.storageService.set('sub', claims.sub);
    }
    return claims;
  }

  private getUserCountry(accessToken: string) {
    const peopleApiUrl =
      'https://people.googleapis.com/v1/people/me?personFields=addresses';
    const headers = {
      Authorization: `Bearer ${accessToken}`,
    };
    return this.http.get<any>(peopleApiUrl, { headers });
  }
}
