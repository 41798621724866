import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { TogglePasswordDirective } from './toggle-password.directive';
import { OnlyNumbersDirective } from './only-numbers.directive';


@NgModule( {
  declarations: [
    TogglePasswordDirective,
    OnlyNumbersDirective
  ],
  imports: [
    CommonModule
  ],
  exports: [
    TogglePasswordDirective,
    OnlyNumbersDirective
  ]
} )
export class DirectivesModule {}
