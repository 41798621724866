import { Component } from '@angular/core';
import { BsModalRef, BsModalService } from 'ngx-bootstrap/modal';
import { StorageService } from '../../../services/storage.service';

@Component( {
  selector: 'app-error-modal',
  templateUrl: './error-modal.component.html',
  styleUrls: ['./error-modal.component.scss']
} )
export class ErrorModalComponent {
  modalRef?: BsModalRef;
  error: any;

  constructor( public modalService: BsModalService,
               private storage: StorageService ) {}

  ngOnInit(): void {

  }
}
