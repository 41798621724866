import { Component, OnInit } from '@angular/core';
import { NavigationEnd, Router } from '@angular/router';

@Component( {
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss']
} )
export class AppComponent implements OnInit {
  title = 'landing-keola-lotery';
  view: boolean = false;

  constructor( private router: Router ) { }

  ngOnInit() {
    // Obtener la ruta inicial
    const initialRoute = this.router.url;
    // Verificar la ruta inicial y actualizar la variable view
    this.updateView( initialRoute );

    // Suscribirse al evento NavigationEnd para actualizar la variable view
    this.router.events.subscribe( event => {
      if ( event instanceof NavigationEnd ) {
        const currentRoute = event.url;
        this.updateView( currentRoute );
      }
    } );
  }

  updateView( route: string ) {
    if ( route === '/' ) {
      this.view = true;
      console.log( this.view );
    } else if ( route !== '/' ) {
      this.view = false;
    } else {
      this.view = true;
    }
  }

}
