import { Injectable } from '@angular/core';
import { environment } from '../../environments/environment';
import { map } from 'rxjs';
import { HttpClient } from '@angular/common/http';

@Injectable( {
  providedIn: 'root'
} )
export class AwardsService {
  url = environment.apiUrlKeola;

  constructor( private http: HttpClient ) { }

  get( module, type ) {
    let url = this.url + module + '/' + type;

    return this.http.get( url ).pipe(
      map( ( resp: any ) => {
        return resp;
      } )
    );
  }

  getAwardforCountry( module, type, idCountry ) {
    let url = this.url + module + '/' + type + '/' + idCountry;

    return this.http.get( url ).pipe(
      map( ( resp: any ) => {
        return resp;
      } )
    );
  }
}
