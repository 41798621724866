import {
  AfterViewInit,
  Component,
  DoCheck,
  ElementRef,
  EventEmitter,
  Input,
  OnChanges,
  OnInit,
  Output,
  SimpleChanges,
  ViewChild
} from '@angular/core';

@Component( {
  selector: 'app-input-code',
  templateUrl: './input-code.component.html',
  styleUrls: ['./input-code.component.scss']
} )
export class InputCodeComponent implements OnInit, DoCheck, OnChanges, AfterViewInit {

  @Output() cardNumberEvt = new EventEmitter<string>();
  @Input() inputCard: string;
  @Input() secretCard: boolean;
  @Input() readonly = false;

  @ViewChild( 'input1', { read: ElementRef } ) input1: ElementRef;
  @ViewChild( 'input2', { read: ElementRef } ) input2: ElementRef;
  @ViewChild( 'input3', { read: ElementRef } ) input3: ElementRef;
  @ViewChild( 'input4', { read: ElementRef } ) input4: ElementRef;

  completed1: boolean;
  completed2: boolean;
  completed3: boolean;

  cardNumber: string;

  constructor() { }

  ngAfterViewInit(): void {
    this.input1.nativeElement.focus();
  }

  ngOnInit() {

  }

  ngOnChanges( changes: SimpleChanges ) {
    if ( changes.inputCard && changes.inputCard.currentValue ) {
      this.setFrecuentCardNumber();
    }
  }

  ngDoCheck() {
    this.emit();
  }

  emit() {
    this.cardNumber = `${this.input1?.nativeElement.value}${this.input2?.nativeElement.value}${this.input3?.nativeElement.value}${this.input4?.nativeElement.value}`;
    this.cardNumberEvt.emit( this.cardNumber );
  }

  setFrecuentCardNumber() {
    this.input1.nativeElement.value = this.inputCard.substr( 0, 1 );
    this.input2.nativeElement.value = this.inputCard.substr( 1, 1 );
    this.input3.nativeElement.value = this.inputCard.substr( 2, 1 );
    this.input4.nativeElement.value = this.inputCard.substr( 3, 1 );
  }

  deleteFrecuentCard() {
    this.input1.nativeElement.value = '';
    this.input2.nativeElement.value = '';
    this.input3.nativeElement.value = '';
    this.input4.nativeElement.value = '';
    this.inputCard = null;
  }

  input1Change() {
    if ( this.input1.nativeElement.value.length === 1 && !this.completed1 ) {
      this.input2.nativeElement.focus();
      this.completed1 = true;
    } else if ( this.input1.nativeElement.value.length < 2 && this.inputCard ) {
      this.deleteFrecuentCard();
    }
    this.emit();
  }

  input2Change() {
    if ( this.input2.nativeElement.value.length === 1 && !this.completed2 ) {
      this.input3.nativeElement.focus();
      this.completed2 = true;
    } else if ( this.input2.nativeElement.value.length < 2 && this.inputCard ) {
      this.deleteFrecuentCard();
    }
    this.emit();
  }

  input3Change() {
    if ( this.input3.nativeElement.value.length === 1 && !this.completed3 ) {
      this.input4.nativeElement.focus();
      this.completed3 = true;
    } else if ( this.input3.nativeElement.value.length < 2 && this.inputCard ) {
      this.deleteFrecuentCard();
    }
    this.emit();
  }

  input4Change() {
    if ( this.input4.nativeElement.value.length < 2 && this.inputCard ) {
      this.deleteFrecuentCard();
    }
    this.emit();
  }


}
