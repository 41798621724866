import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { environment } from '../../environments/environment';
import { map } from 'rxjs';
import { User } from '../models/user';

@Injectable({
  providedIn: 'root',
})
export class AuthService {
  url = environment.apiUrlKeola + '/auth';
  private email: string;
  user: User;

  constructor(private http: HttpClient) {}

  register(params) {
    let url = this.url + '/new-user';
    console.log(`Register endpoint: ${url}`, params);
    const headers = {
      'x-api-key': environment.x_api_key,
    };

    return this.http.post(url, params, { headers }).pipe(
      map((resp: any) => {
        return resp;
      })
    );
  }

  registerGoogle(params) {
    let url = this.url + '/user-with-google';
    console.log(`Register Google endpoint: ${url}`, params);

    const headers = {
      'x-api-key': environment.x_api_key,
    };

    return this.http.post(url, params, { headers }).pipe(
      map((resp: any) => {
        return resp;
      })
    );
  }

  loginGoogle(params) {
    let url = this.url + '/sign-in-with-google';
    console.log(`Login Google endpoint: ${url}`, params);

    const headers = {
      'x-api-key': environment.x_api_key,
    };

    return this.http.post(url, params, { headers }).pipe(
      map((resp: any) => {
        return resp;
      })
    );
  }

  validationCode(params) {
    let url = this.url + '/valide-verification-code';
    console.log(`Validation Code endpoint: ${url}`, params);

    return this.http.post(url, params).pipe(
      map((resp: any) => {
        return resp;
      })
    );
  }

  login(params: any) {
    let url = this.url + '/signin';
    console.log(`Login endpoint: ${url}`, params);
    return this.http.post(url, params).pipe(
      map((resp: any) => {
        return resp;
      })
    );
  }

  updatePassword(params) {
    const url = `${this.url}/restore-password?passwordChangeType=password_change`;
    console.log(`Update Password endpoint: ${url}`, params);

    const headers = {
      'x-api-key': environment.x_api_key,
    };

    return this.http.post(url, params, { headers }).pipe(
      map((resp: any) => {
        return resp;
      })
    );
  }

  setEmail(email: string) {
    this.email = email;
  }

  getEmail(): string {
    return this.email;
  }
}
